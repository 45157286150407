import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="bioresonanz" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Bioresonanz-Therapie`}</h1>
      <h3 {...{
        "id": "bioresonanz-therapie"
      }}>{`Bioresonanz-Therapie`}</h3>
      <p>{`Aus der Physik wissen wir, dass unser gesamtes Universum von elektromagnetischen Schwingungen gesteuert wird, die unsichtbar alles in Bewegung halten – von der Erde selbst über jeden Gegenstand bis hin zu den 60 Billionen Zellen in unserem Körper.`}</p>
      <p>{`Wir sind gesund, wenn diese Schwingungen harmonisch sind, d.h., die Kommunikation der Zellen untereinander funktioniert. Krankheit dagegen ist durch disharmonische (pathologische) Schwingungen gekennzeichnet. Das gesamte Schwingungsspektrum („Aura“) wird von unserer Körperoberfläche wie das harmonische Klangbild eines Orchesters abgestrahlt. Diese Signale können über Elektroden aufgenommen und über Kabel in das BRT-Gerät geleitet werden.`}</p>
      <p>{`Wenn man bei dem Beispiel des Orchesters bleibt, würde das einer Tonbandaufnahme über Mikrofon entsprechen. So wie man im Tonstudio das Klang- (Schwingungs-) Spektrum bearbeiten und „Falschspieler“ löschen kann, so werden bei der BRT die harmonischen Schwingungen verstärkt, die disharmonischen (= krank machenden) Schwingungen invers geschaltet, d.h., spiegelbildlich umgekehrt und damit neutralisiert.`}</p>
      <p>{`Häufig werden gerade chronische Krankheiten durch ein Ungleichgewicht oder durch Blockaden im elektromagnetischen Schwingungsmuster unterhalten und der Körper in seinen Möglichkeiten zur Selbstheilung behindert. Dies kann die BRT durch eine Harmonisierung und Normalisierung der Zellkommunikation verbessern. Ein seit vielen Jahren bewährtes Anwendungsgebiet ist die Behandlung der `}<a parentName="p" {...{
          "href": "/beschwerdebilder/allergien_unvertraeglichkeiten"
        }}>{`Allergie`}</a>{` (Heuschnupfen, Lebensmittel, Tierepithelien u.v.a.m.) nach vorangegangener `}<a parentName="p" {...{
          "href": "/diagnoseverfahren/bioresonanz-testung"
        }}>{`Bioresonanz-Testung`}</a>{`.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      